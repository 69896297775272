.about-message {
  /* line-height: 12px; */
}

.about-message font,
.about-message span {
  /* font-size: 1.5px; */
  /* background: none;
  background-color: rgba(0, 0, 0, 0);
  background-color: transparent !important; */
  /* color: black; */
}

.payable {
  font-size: 16px;
  font-weight: 900;
}

/* media */
@media (min-width: 1400px) {
  .swiper-img {
    width: 50%;
    height: 50%;
    object-fit: cover;
  }
  .payment-gateway {
    height: 150px;
    width: 150px;
    object-fit: contain;
  }
}

.app-link-heading {
  font-size: 17px;
  font-weight: 400;
}

.trapezoid-left {
  border-bottom: 25px solid black;
  border-left: 0 solid transparent;
  border-right: 20px solid transparent;
  border-top: 0 solid transparent;
}

.trapezoid-down {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 25px solid black;
}

.trapezoid-right {
  border-bottom: 25px solid black;
  border-left: 20px solid transparent;
  border-right: 0 solid transparent;
  border-top: 0 solid transparent;
}

.card-banner {
  display: flex;
  position: relative;
  width: 100%;
}

.parent > .card-banner:before,
.parent > .card-banner:after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
}

.card-banner:before {
  border-bottom: 38px solid #402f73;
  border-right: 15px solid transparent;
  margin: 0 10px;
  z-index: -1;
}

.card-banner:after {
  border-bottom: 38px solid #6bc1e4;
  border-right: 15px solid transparent;
  /* margin: 0px 10px; */
  z-index: -1;
}

#card-label {
  margin: 8px 24px;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
}

/* Ensure the container hides overflow and takes the full width */
.marquee-container {
  overflow: hidden;
  width: 100%; /* Full width of the parent container */
  position: relative;
  height: 50px; /* Adjust the height as necessary */
}

/* This is the wrapper for the marquee content */
.marquee-content {
  display: flex;
  animation: marquee 30s linear infinite; /* Slow down speed to 30 seconds */
}

/* Marquee content */
.marquee__content {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 50px;
  align-items: center;
}

/* Individual list item style */
.list__item {
  padding-right: 1rem; /* Space between items */
  font-size: 1rem;
  color: red;
  white-space: nowrap;
  display: inline-block;
  width: auto;
}

.marquee__content a.uk-link-reset {
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, .5) 0px 0px 5px 0px;
}

/* Marquee scrolling animation */
@keyframes marquee {
  0% {
    transform: translateX(100%); /* Start off-screen on the right */
  }
  100% {
    transform: translateX(-100%); /* End off-screen on the left */
  }
}

/* Pause animation on hover */
.marquee-container:hover .marquee-content {
  animation-play-state: paused;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .list__item {
    font-size: 0.875rem; /* Smaller font on mobile */
  }
}


/*!* Enable animation *!*/
/*.marquee__content {*/
/*  animation: scroll 60s linear infinite;*/
/*}*/

/* Pause on hover */
.marquee--hover-pause:hover .marquee__content {
  animation-play-state: paused;
}

.color-reverse {
  background-color: transparent;
}

.color-reverse > a {
  color: white;
}

.color-reverse:hover {
  transition: all 0.8s ease;
  background-color: white;
}

.color-reverse > a:hover {
  color: #6bc1e4;
}

li::marker {
  /* content: '❤️'; */
  color: red;
}

.marquee__content a {
  color: red;
}
