.gallery-image {
  width: 100%;
  height: 250px;
  cursor: pointer;
  position: relative;
}

.gallery-image > img {
  opacity: 1;
  display: block;
  width: 100%;
  height: 250px;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 5px;
}

.img-label {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.gallery-image:hover img {
  opacity: 0.3;
}

.gallery-image:hover .img-label {
  opacity: 1;
}

.img-label > .text {
  font-size: 16px;
  color: black;
  font-weight: 600;
  padding: 16px 32px;
}

.yarl__thumbnails_thumbnail_active {
  border-color: rgba(100,150,255) !important;
}
