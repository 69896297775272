/*.custom-modal-backdrop {*/
/*    position: fixed;*/
/*    background-color: rgba(0, 0, 0, 0.5);*/
/*    top: 0;*/
/*    right: 0;*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    z-index: 1025;*/
/*}*/

/*.custom-modal {*/
/*    background-color: white;*/
/*    margin: auto;*/
/*    height:500px;*/
/*    vertical-align: middle;*/
/*    top: 6%;*/
/*}*/

/*.modal-card-body{*/
/*    max-height: calc(100vh - 220px);*/
/*    overflow-y: auto;*/
/*}*/

/*.custom-header{*/
/*    justify-content: flex-end;*/
/*}*/

/*.f-left {*/
/*    float: left;*/
/*}*/

/*.notice-img {*/
/*    height: 100%;*/
/*    width: 100%;*/
/*}*/


.modal {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    opacity: 0 !important;
    visibility: visible !important;
    transform: scale(1.1) !important;
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s !important;
    /*z-index: 99999 !important;*/
}
.modal-content {
    position: absolute !important;
    top: 52.5% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: transparent !important;
    padding: 1rem 1.5rem !important;
    width: 50% !important;
    height: 52vh !important;
    border-radius: 0.5rem !important;
}
.close-button {
    float: right !important;
    width: 1.5rem !important;
    line-height: 1.5rem !important;
    text-align: center !important;
    cursor: pointer !important;
    border-radius: 0.25rem !important;
    background-color: lightgray !important;
}
.close-button:hover {
    background-color: darkgray !important;
}
.show-modal {
    opacity: 1 !important;
    visibility: visible !important;
    transform: scale(1.0) !important;
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s !important;
    z-index: 9999 !important;
}
.uk-padding-popup{
    padding: 5px !important;
}

.popupImage img{
    height: 30rem !important;
}
