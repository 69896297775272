.contact {
  background-color: #f6f9fd;
  box-sizing: border-box;
  color: rgb(68, 68, 68);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 60px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 60px;
  text-align: start;
}

.info-box {
  color: #0b2341;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  background: #fff;
}
.contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
    background: #fff;
  }
